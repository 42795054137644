<template>
  <div class="subpage">

    <p class="error">Sorry, but this page doesn't exist.</p>

  </div>
</template>

<script>

export default {
  name: 'PageNotFound'
}

</script>

<style lang="scss" scoped>

.error {
  color: red;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin: 15px;
}

</style>